import React from 'react'

import { RootComponent } from 'redesign/app/RootComponent'
import { RoleIdProvider } from 'redesign/providers/RoleIdProvider'

import SEO from '../components/SEO'

import { RolePage as RolePageComponent } from 'redesign/modules/Role'

const RolePage = () => (
  <>
    <SEO title="Home" />
    <RootComponent menuSelectedKeys={['roles']}>
      <RoleIdProvider>
        <RolePageComponent />
      </RoleIdProvider>
    </RootComponent>
  </>
)

export default RolePage
